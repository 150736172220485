import React, { Fragment } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Article from "../../components/article"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import Breadcrumb from "../../components/breadcrumb"
import { ILocation } from "../../interface/location"

const SikringMotBrannPage: React.FC<{ location: ILocation; data: any }> = ({
    location,
    data,
}) => {
    const { crumbs } = useBreadcrumb({
        location,
        crumbLabel: "Sikring mot brann",
        crumbSeparator: " / ",
        crumbActiveStyle: "currentCrumb",
    })
    const {
        pagename,
        heading,
        ingress,
        subheading,
        content,
        contact,
        contactEmail,
        contactTel,
        contactPicture,
        additionalFiles,
    } = data.brannsikring.edges[0].node.frontmatter

    const ingressData = { ingress, pagename }

    return (
        <Fragment>
            <Layout location={location} hero={true} ingress={ingressData}>
                <Breadcrumb crumbs={crumbs} />
                <Article
                    heading={heading}
                    subtitle={subheading}
                    content={content}
                    contact={contact}
                    contactEmail={contactEmail}
                    contactTel={contactTel}
                    contactPicture={
                        contactPicture.childImageSharp.gatsbyImageData
                    }
                    additionalFiles={additionalFiles}
                />
            </Layout>
        </Fragment>
    )
}

export default SikringMotBrannPage

export const data = graphql`
    query BrannsikringQuery {
        brannsikring: allMarkdownRemark(
            filter: {
                fileAbsolutePath: {
                    regex: "/CMSContent/Subpages/sikring-mot-brann/"
                }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        pagename
                        heading
                        ingress
                        subheading
                        content
                        contact
                        contactEmail
                        contactTel
                        contactPicture {
                            childImageSharp {
                                gatsbyImageData(
                                    quality: 100
                                    layout: FULL_WIDTH
                                )
                            }
                        }
                        additionalFiles {
                            title
                            url
                            fileurl {
                                relativePath
                            }
                        }
                    }
                }
            }
        }
    }
`
